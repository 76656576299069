<template>
    <div
        v-if="show"
        class="tree-shape-dialog"
    >
        <tree-shape-view
            :title="title"
            :allCheck="allCheck"
            :multiple="multiple"
            :checkUserOnly="checkUserOnly"
            :checkAll="checkAll"
            :treeShowList="treeShowList"
            :selectTreeNodeData="selectTreeNodeData"
            :breadcrumbsList="breadcrumbsList"
            :optionalLoadTitle="optionalLoadTitle"
            :dropDownSearchType="dropDownSearchType"
            :searchDropdownData="searchDropdownData"
            :searchOptionalLoadTitle="searchOptionalLoadTitle"
            :initLoading="initLoading"
            @selectFocus="selectFocus"
            @selectInput="selectInput"
            @handlerAllTreeNodeClick="handlerAllTreeNodeClick"
            @handlerTreeNodeClick="handlerTreeNodeClick"
            @treeNodeNextLevelClick="treeNodeNextLevelClick"
            @handlerDelTreeNode="handlerDelTreeNode"
            @changeTreeListLevel="changeTreeListLevel"
            @closeSearchPullDown="closeSearchPullDown"
            @closeDialog="closeDialog"
            @determine="determine"
        >
        </tree-shape-view>
    </div>
</template>
<script>
import {
    setOriginTreeListStatus,
    splicingTreeShape,
    useTreeListData,
    changeCheckedStatus,
    selectCheckedDeDuplication,
    allCheckedFalse
} from "../hook.js";

import TreeShapeView from './TreeShapeView/index.vue'
export default {
    name: "XkTreeShape",
    components: {
        TreeShapeView
    },
    props: {
        title: {
            type: String,
            default: () => {
                return '请选择';
            },
        },
        list: Array,
        selectObjData: Array,
        multiple: {
            type: Boolean,
            default: () => {
                return true;
            },
        },
        checkUserOnly: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
        checkAll: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
        show: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
        initLoading: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
        isStudentCom: {
            type: Boolean,
            default: () => {
                return false;
            },
        }
    },
    created() {
        let _this = this;
        this.init();
        this.$on('getNewTreeList', () => {
            _this.getNewTreeList()
        })
    },
    mounted () {
        this.$nextTick(() => {
            const body = document.querySelector('body');
            if (this.show) {
                body.append(this.$el)
            }
        })
    },
    data() {
        return {
            allCheck: false,
            /**
             * 下拉搜索
             * dropDownSearchType 是否展开下拉数据
             * sourceDataType 后台请求数据来源
             * 如果是通过下级来的就是 level
             * 如果是搜索来的就是 search
             * searchDropdownData 回显下拉数据
             * searchOptionalLoadTitle 回显数据展示名称
             *
             * */
            dropDownSearchType: false,
            sourceDataType: 'level',
            searchDropdownData: [],
            searchOptionalLoadTitle: '加载中',
            /**
             * alreadySelectedData 已选择数据(初始化数组)
             * selectTreeNodeData 已选择数据(对象)
             * originTreeData 树形数据
             * treeNodeKey 当前属性数据下标
             * currentLevel 当前层级
             * */
            alreadySelectedData: [],
            selectTreeNodeData: [],
            originTreeData: {},
            treeNodeKey: "topFloor",
            currentLevel: '',
            /**
             * 面包屑数据
             *
             * */
            breadcrumbsList: [{
                id: 'topFloor',
                name: '全部'
            }],
            /**
             * 左侧加载数据显示
             * */
             optionalLoadTitle: '加载中'
        };
    },
    computed: {
        treeShowList () {
            console.log(this.originTreeData[this.treeNodeKey],'computed')
            return this.originTreeData[this.treeNodeKey];
        }
    },
    methods: {
        /**
         * @Description: 初始化函数
         * @DoWhat: 初始化函数，调用数据
         * @UseScenarios: 组件加载后执行
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2023-02-15 14:21:21
         */

        init() {
            console.log("触发", this.treeNodeKey, this.breadcrumbsList,'111--111',this.selectObjData );
            this.initAssignment()
            this.listerOriginList()
        },
        initAssignment () {
            this.alreadySelectedData = JSON.parse(JSON.stringify(this.selectObjData)).map((item) => {
                return item.id;
            })
            console.log(this.selectObjData,'==============')
            this.selectTreeNodeData = JSON.parse(JSON.stringify(this.selectObjData))
            if (this.initLoading) {
                this.optionalLoadTitle = '加载中'
            } else if (this.list && this.list.length == 0) {
                this.optionalLoadTitle = '暂无数据'
            }
        },
        getNewTreeList () {
            if (this.sourceDataType == 'level') {
                this.listerOriginList();
                if(this.list && this.list.length == 0) {
                    this.optionalLoadTitle = '暂无数据'
                }
            } else {
                // 判断是否是只能选择学生的弹窗组件
                if(this.isStudentCom) {
                    this.searchDropdownData = this.list.filter((item) => item.type == "student")
                } else {
                    this.searchDropdownData = this.list;
                }
                console.log(this.searchDropdownData,'this.searchDropdownData=============')
                console.log(this.alreadySelectedData,'alreadySelectedData')
                this.searchDropdownData.forEach((item) => {
                    this.alreadySelectedData.forEach((subItem) => {
                        if (item.id == subItem) {
                            this.$set(item, 'checked', true)
                        } else {
                            this.$set(item, 'checked', false)
                        }
                    })
                })
                if(this.list && this.list.length == 0) {
                    this.searchOptionalLoadTitle = '暂无数据'
                }
                console.log(this.searchDropdownData,'this.searchDropdownData')
            }

        },
        /**
         * @Description: list数据来源变化
         * @DoWhat: 获取最新list数据
         * @UseScenarios:  向originTreeData赋值
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2023-02-15 16:11:57
         */
        listerOriginList() {
            let _this = this;
            this.originTreeData = setOriginTreeListStatus(
                this.originTreeData,
                this.treeNodeKey,
                this.list,
                this.alreadySelectedData,
                this.selectTreeNodeData,
                _this,
            );
            this.selectTreeNodeData = selectCheckedDeDuplication(this.selectTreeNodeData)
            this.isCurrentSelectionAll()
            console.log(this.originTreeData, this.alreadySelectedData,"this.originTreeData");
        },
        /**
         * @Description: 判断是否全选
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-04-19 19:00:29
         */
        isCurrentSelectionAll () {
            let selTree = this.showList();
            console.log(selTree,'selTree')
            let isCheckList = selTree.filter((item) => {
                return !item.checked
            })

            if (isCheckList.length == 0) {
                this.allCheck = true;
            } else {
                this.allCheck = false;
            }

        },
        showList() {
            return this.originTreeData[this.treeNodeKey];
        },
        /**
         * @Description: 去除已经选择的删除项
         * @DoWhat: 去除删除项
         * @UseScenarios: 左侧选中的数据删除
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2023-02-15 16:52:21
         * @param {*} e
         * @param {*} item
         */
        handlerDelTreeNode(data) {
            const { e, item } = data;
            this.deletedCheckedTreeNode(false, [item]);
        },
        /**
         * @Description: 树形列表item点击事件
         * @DoWhat: 去除选中
         * @UseScenarios: 树形组件选中/去除选中
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2023-02-15 16:48:21
         * @param {*} e
         * @param {*} item
         */
        handlerTreeNodeClick(data) {
            const { e, item } = data;
            const tar = e.target,
                checkedType = tar.checked;
            this.deletedCheckedTreeNode(checkedType, [item]);
        },
        /**
         * @Description: 点击下一级
         * @DoWhat: 树形结构点击下一级
         * @UseScenarios: 点击下一级
         * @Attention: 无
         * @Author: 我的
         * @Date: 2023-02-16 16:40:33
         */
        treeNodeNextLevelClick(data) {
            const { treeNode } = data;
            console.log(treeNode ,'ev, treeNode ')
            this.breadcrumbsList.push(treeNode);
            this.optionalLoadTitle = '加载中';
            this.sourceDataType = 'level';

            this.getNextLevelTreeList(treeNode);
            // 修改指针
            this.changeTreeNodeKey(treeNode.id);
            console.log(treeNode, this.breadcrumbsList, "treeNode");
        },
        /**
         * @Description: 触发操作
         * @DoWhat: 操作origins数据
         * @UseScenarios: 操作原始数据
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2023-02-15 16:49:08
         * @param {*} checkedType
         * @param {*} item
         */
        deletedCheckedTreeNode(checkedType, item) {
            console.log(this.treeShowList,'this.treeShowList')
            console.log(checkedType, item, '++', this.multiple)

            let _this = this;
            const [addTreeListData, removeTreeListData] = useTreeListData(
                    this.selectTreeNodeData,
            );

            if (this.multiple) {
                switch (checkedType) {
                    case true:
                        this.selectTreeNodeData = selectCheckedDeDuplication(
                            addTreeListData(item),
                        );
                        break;
                    case false:
                        this.selectTreeNodeData = removeTreeListData(item);
                        break;
                    default:
                        break;
                }
            } else {
                this.selectTreeNodeData = removeTreeListData(this.selectTreeNodeData)
                allCheckedFalse(this.originTreeData, _this)

                this.alreadySelectedData = [];
                this.selectTreeNodeData = item;
                console.log(
                    this.alreadySelectedData,'alreadySelectedData',
                    this.selectTreeNodeData, 'selectTreeNodeData',
                    this.originTreeData, 'originTreeData'
                )
                switch (checkedType) {
                    case true:
                        this.selectTreeNodeData = item;
                        break;
                    case false:
                        this.selectTreeNodeData = [];
                        break;
                    default:
                        break;
                }
            }



            this.originTreeData = splicingTreeShape(
                this.originTreeData,
                this.treeNodeKey,
                changeCheckedStatus(checkedType, item, this.treeShowList, _this),
            );
            console.log(
                this.originTreeData,
                this.selectTreeNodeData,
                this.list,
                "数据变化",
            );
            // 判断是否全选
            this.isCurrentSelectionAll()
            // 重新赋值选中数据数组（再次获取新list时使用）
            this.getNewSelTreeNode()

        },
        /**
         * @Description: 重新赋值已选中的数组
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-04-20 09:39:22
         */
        getNewSelTreeNode () {
            console.log(this.selectTreeNodeData,'this.selectTreeNodeData')
            this.alreadySelectedData = [];
            this.selectTreeNodeData.forEach((item) => {
                this.alreadySelectedData.push(item.id);
            })
        },
        /**
         * @Description: 全部选择
         * @DoWhat: 对当前右侧列表进行全部选择
         * @UseScenarios: 点击全选，全不选，去除或者添加已选择
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2023-02-16 15:47:02
         */
        handlerAllTreeNodeClick(data) {
            const { ev } = data;
            const tar = ev.target,
                checkedType = tar.checked;

            this.deletedCheckedTreeNode(checkedType, this.treeShowList);
        },
        // 搜索相关
        /**
         * @Description: 展开搜索框
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-04-20 10:56:08
         */
        selectFocus () {
            this.searchDropdownData = [];
            this.dropDownSearchType = true;
            this.sourceDataType = 'search';
        },
        /**
         * @Description: 搜索
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-04-20 11:09:13
         */
         selectInput (inputVal) {
            this.$emit("getNextLevelTreeList", {id: '', name: inputVal});
         },
        /**
         * @Description: 关闭下拉
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-04-20 11:14:42
         */
         closeSearchPullDown () {
            console.log('触发')
            this.dropDownSearchType = false;
            // this.$eventBroadcast('closeSearchPullDown')
         },
        //  与本地存储做交互
        /**
         * @Description: 点击面包屑
         * @DoWhat: 点击面包屑切换树形数据
         * @UseScenarios: 点击面包屑获取最新数据
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2023-02-16 17:15:17
         */
        changeTreeListLevel(data) {
            const item = data.item;
            console.log(this.originTreeData, item, "this.originTreeData");
            console.log(item.id , this.treeNodeKey)
            // 1.删除面包屑后面的数据
            // 2.切换origin对象的下标，获取list。
            if (item.id !== this.treeNodeKey) {
                let idx = this.breadcrumbsList.indexOf(item);
                console.log(idx, this.breadcrumbsList, "idx");
                this.breadcrumbsList = this.breadcrumbsList.filter((item, index) => {
                    return index <= idx ;
                });

                console.log('修改列表')
                // 判断修改指针
                this.changeTreeNodeKey(item.id);
                // 判断是否全选
                this.isCurrentSelectionAll()
            }
            console.log(this.breadcrumbsList, "this.breadcrumbsList");
        },
        //  后台交互
        /**
         * @Description: 与后台交互获取下一级数据, 抛出事件
         * @DoWhat: 获取最新下一级
         * @UseScenarios: 获取最新数据, 吐出数据为当前点击下一级的详情数据
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2023-02-16 16:43:21
         */
        getNextLevelTreeList(treeNode) {
            this.$emit("getNextLevelTreeList", {id: treeNode.id, name: ''});
        },
        setAlreadySelectedData () {
            this.$emit('setAlreadySelectedData', this.alreadySelectedData)
        },
        // 工具函数
        /**
         * @Description: 修改TreeNode指针
         * @DoWhat: 修改指针，获取右侧树形列表数据
         * @UseScenarios: 修改treeNode指针
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2023-02-16 17:27:02
         */
        changeTreeNodeKey(id) {
            this.treeNodeKey = id;
        },
        // 提交/关闭
        closeDialog () {
            this.$emit('closeDialog')
        },
        determine () {
            console.log('提交', this.selectTreeNodeData)
            this.$emit('determine', this.selectTreeNodeData)
        },
    },
    watch: {
        show: {
            handler() {
                console.log(this.show, "watch-打开");
            },
            deep: true,
        },
        list: {
            handler() {
                console.log(this.list, "watch-可选择数据");
            },
            deep: true,
        },
        originTreeData: {
            handler() {
                console.log(this.originTreeData, "watch-整体数据结构");
            },
            deep: true,
        },
        alreadySelectedData: {
            handler() {
                console.log(this.alreadySelectedData, "watch-选中的数据数组");
            },
            deep: true,
        },
        selectTreeNodeData: {
            handler() {
                console.log(this.selectTreeNodeData, "watch-选中的数据数组对象");
            },
            deep: true,
        },
        treeNodeKey: {
            handler() {
                console.log(this.treeNodeKey, "watch-当前key");
            },
            deep: true,
        },
        breadcrumbsList: {
            handler() {
                console.log(this.breadcrumbsList, "watch-面包屑数据");
            },
            deep: true,
        },
        allCheck: {
            handler() {
                console.log(this.allCheck, "watch-是否全选");
            },
            deep: true,
        },
        sourceDataType: {
            handler() {
                console.log(this.sourceDataType, "watch-回显数据类型");
            },
            deep: true,
        },
        initLoading: {
            handler(nVal, oVal) {
                if (oVal && !nVal) {
                    console.log("[watch][initLoading]", this.selectObjData)
                    this.selectObjData = this._.cloneDeep(this.selectTreeNodeData)
                    this.init()
                }
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.tree-shape-dialog {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    background-color: rgba($color: #000000, $alpha: 0.6);
    z-index: 9999;
}
</style>
